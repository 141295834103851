/* eslint-disable */
import React from 'react';
import { useEvent } from 'hooks/Hooks';
import UnityContainer from 'components/UnityContainer/UnityContainer';
import {isMobile} from 'react-device-detect';
import AfterpayVideo from 'components/AfterpayVideo/AfterpayVideo';

const EVENTS = [
  {
    type: 'open_link',
    title: 'Shopping',
    description: 'Outward link',
  },
  {
    type: 'open_video',
    title: 'Openning Capn Video',
    description: '',
  },
];

const containerStyle = { 
  backgroundColor: 'black', 
  minWidth: '100vw', 
  minHeight: '90vh', 
  height: '100vh', 
  overflowY: 'hidden', 
  overflowX: 'hidden' 
};

const OpenGridContainer = (props) => {
  const { backgroundImg, dynamicBuilds } = props;
  const [showCapnVideo, setShowCapnVideo] = React.useState(false);

  const unityContainer = React.createRef();

  EVENTS.forEach(event => {
    useEvent(event.type, 
      (e) => {
        if (event.type === 'open_video') setShowCapnVideo(true); 
        if (event.type === 'exit_game') window.open('https://capncrunch.com'); 
        if (event.type === 'open_link') window.open(e.detail.link, '_blank'); 
      });
  }, []);

  const fullScreen = () => {unityContainer.current.unityInstance && unityContainer.current.unityInstance.SetFullscreen(1)};

  return (
  <>
    <div style={containerStyle}>
      <div className="fullscreenUnity" onClick={fullScreen} />
      <UnityContainer unityUrl='.' ref={unityContainer} filename={isMobile && dynamicBuilds ? 'Mobile' : 'Build'} directory={isMobile && dynamicBuilds ? 'Mobile' : '.'} />
    </div>

    <AfterpayVideo show={showCapnVideo} closeModal={() => setShowCapnVideo(false)} /> 
  </>
  );
};

export default OpenGridContainer;
