import React from 'react';
import classNames from 'classnames';
import paths from 'pages/Router/paths';
import { useHistory } from "react-router-dom";
import GameModal from 'components/GameModal/GameModal';

const Home = () => {

  const history = useHistory();
  const [showGame, setShowGame] = React.useState(false);
  
  return (
    <>
    <GameModal show={showGame} closeModal={() => setShowGame(false)}/>
    
    <section className="section is-main-section" style={{ padding: 0 }} >
      <div align="center" style={{ height: '100vh', width: '100%', verticalAlign: 'middle', backgroundSize: 'cover', backgroundImage: 'url("https://res.cloudinary.com/hqsibq8j6/image/upload/v1598380787/OpenGrid_Background_Blur_082420_xsggnz.jpg")'}}>
        <h1 className="home-title">The future of virtual events and brand experiences.</h1>
        <button
              type="button"
              onClick={() => history.push(paths.DEMO) }
              className={classNames('button', 'is-black')}
              style={{
                position: 'relative',
                top: '0',
                margin: 10
              }}>
            Enter
          </button>
      </div>
    </section>
  </>
  );
};

export default Home;
