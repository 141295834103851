import React, { useState } from 'react';
import PropTypes from 'prop-types';

import NavBar from '../Navigation/NavBar/Navbar';
// eslint-disable-next-line
// import Aside from '../Navigation/Aside';
// eslint-disable-next-line
// import Footer from '../Navigation/Footer';

import classes from './Layout.module.scss';

const Layout = ({ children }) => {
  const [asideMobileActive, setAsideMobileActive] = useState(false);

  const handleMobileToggle = () => {
    document.documentElement.classList.toggle('has-aside-mobile-expanded');
    setAsideMobileActive(!asideMobileActive);
  };

  return (
    <>
      <NavBar
        handleMobileToggle={handleMobileToggle}
        asideMobileActive={asideMobileActive}
      />
      

      <div className={classes.layout}>{children}</div>

    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
