/* eslint-disable */
import React from 'react';
// import { toastr } from 'react-redux-toastr';
// import { useEvent } from 'hooks/Hooks';
import OpenGridContainer from 'components/OpenGridContainer/OpenGridContainer';
// import UnityContainer from 'components/UnityContainer/UnityContainer';
// import GameModal from 'components/GameModal/GameModal';
// import {isMobile} from 'react-device-detect';

// import CapnVideo from 'components/CapnVideo/CapnVideo';



const Demo = () => {

  // const [showGame, setShowGame] = React.useState(false);

  const dynamicBuilds = false;

  
  return (<>
    {/* <CapnVideo show={showGame} closeModal={() => setShowGame(false)} /> */}
    <OpenGridContainer dynamicBuilds={dynamicBuilds} />
      {/* <UnityContainer unityUrl='.' ref={unityContainer} filename={isMobile && dynamicBuilds ? 'Mobile' : 'Build'} directory={isMobile && dynamicBuilds ? 'Mobile' : '.'} /> */}
  </>
  );
};

export default Demo;
