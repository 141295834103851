import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';

import LanguageWrapper from 'components/LanguageWrapper/LanguageWrapper';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import firebase from 'firebase.js';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore'; // <- needed if using firestore
import { configureStore } from './state/store';
import './index.scss';
import Router from './pages/Router/Router';
import * as serviceWorker from './serviceWorker';
import './assets/css/main.css';

const { store, persistor } = configureStore({});

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  enableClaims: true // Get custom claims along with the profile
};

const app = (
  <Provider store={store}>
    <ReactReduxFirebaseProvider 
      firebase={firebase} 
      config={rrfConfig} 
      dispatch={store.dispatch} 
      createFirestoreInstance={createFirestoreInstance} >
      <LanguageWrapper>
        <PersistGate persistor={persistor}>
          <ReduxToastr
            newestOnTop={false}
            preventDuplicates
            position="bottom-right"
            getState={state => state.toastr}
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
          <Router />
        </PersistGate>
      </LanguageWrapper>
    </ReactReduxFirebaseProvider>

  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
