/* eslint-disable */
import React from 'react';
import ReactDOM from "react-dom";

const GameModal = ( props ) => {
  const { show, closeModal } = props;

  const modal =  (<>
      <div className={show ? "overlayPortal" : "hidePortal"} onClick={closeModal} />
      <div className={show ? "modalPortal" : "hidePortal"}>
        <button onClick={closeModal}>X</button>
        <iframe className="gameFrame" align="center" frameBorder="0" scrolling="no" 
        src={show ? "https://www.gameflare.com/embed/ultimate-knockout-race" : ""}
        style={{ height:'100%', width:'100%' }}/>
      </div>
    </>)
  return ReactDOM.createPortal(
     modal, document.getElementById("modal-root")
  );
};

export default GameModal;
