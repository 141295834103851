/* eslint-disable */
import React from 'react';
import ReactDOM from "react-dom";
import ReactPlayer from 'react-player'

const AfterpayVideo = ( props ) => {
  const { show, closeModal, url } = props;

  const modal =  (<>
      <div className={show ? "overlayPortal" : "hidePortal"} onClick={closeModal} />
      <div className={show ? "videoPortal" : "hidePortal"}>
        <button className="close-button" onClick={closeModal} style={{ position: 'fixed', top:0, right: 0, zIndex: 1000000 }}>&#10005;</button>
        <ReactPlayer showControls={true} playing={show} height="100vh" width="100vw" url="https://www.youtube.com/embed/zQ69v_DOniw" />
        {/* <iframe 
          width="100%"height="100%"frameBorder="0"
          style={{ maxWidth: '405', width:'100%', height: '100%', border: 'none' }}
        src="" 
        allow="camera; autoplay; encrypted-media;" /> */}
      </div>
    </>)
  return ReactDOM.createPortal(
     modal, document.getElementById("modal-root")
  );
};


export default AfterpayVideo;
